import api from "../../index";
import { configurationVariables } from "../../../environment/index";

const idOrigem = 3; //LojaCambio;
const idHotsite = parseInt(configurationVariables.IdHotsite);

export const getVitrine = async () => {
  try {
    const { data: response, status } = await api.get("/CarrinhoSeguroViagem/GetVitrineSeguroViagem");

    return {
      data: response.data,
      status,
      errorMessage: response.errorMessage
    }

  } catch (error) {
    throw new Error(error + "R2GTS");
  }

};

export const addItemVitrine = async (
  request
) => {
  try {

    const obj = {
      idCliente: request.idCliente,
      idOrigem: idOrigem,
      idHotsite: idHotsite,
      IdDestino: request.idDestino,
      dataPartida: request.dataDe,
      dataRetorno: request.dataAte,
      passageiros: request.passageiros
    };

    const { data: response, status } = await api.post("/CarrinhoSeguroViagem/AddVitrineItemSeguroViagem", obj);

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R6MLI");
  }
};

export const addSeguroViagem = async (
  infoSeguroViagem,
  pacote
) => {
  try {

    const obj = {
      idCliente: infoSeguroViagem.idCliente,
      idOrigem: idOrigem,
      idHotsite: idHotsite,
      nomeProduto: pacote.nomeProduto,
      codigo: pacote.codigo,
      tarifa: pacote.tarifa,
      modalidade: pacote.modalidade,
      valorTotal: pacote.valorTotal
    };

    const { data: response, status } = await api.post("/CarrinhoSeguroViagem/AddSeguroViagem", obj);

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R6MLI");
  }
};

export const addPassageiros = async (
  infoSeguro
) => {
  try {

    const dados = {
      idOrigem,
      idCliente: infoSeguro.idCliente,
      passageiros: infoSeguro.passageiros,

    }

    const { data: response, status } = await api.post(
      "/CarrinhoSeguroViagem/AddPassageiros",
      dados
    );

    return {
      status,
      errorMessage: response?.errorMessage,
      data: response?.data,
    };
  } catch (error) {
    throw new Error(error + "R6MLI");
  }
};



export const addPagamento = async (idCiente, formaPagamento) => {
  try {

    const dados = {
      idOrigem,
      idCliente: idCiente,
      formaPagamento: formaPagamento,
    }


    const { data: response, status } = await api.post(
      "/CarrinhoSeguroViagem/AddPagamento",
      dados
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R1Q98");
  }
};




export const getCoberturas = async (infoSeguro) => {
  try {

    const dados = {
      idOrigem,
      idCliente: infoSeguro.idCliente,
      codigoProduto: infoSeguro?.pacoteSelecionado?.codigo,
      codigoTarifa: infoSeguro?.pacoteSelecionado?.tarifa?.toString(),
      dataPartida: new Date(infoSeguro.dataDe),
      dataRetorno: new Date(infoSeguro.dataAte),
    }




    const { data: response, status } = await api.post(
      "/CarrinhoSeguroViagem/GetCoberturas",
      dados
    );


    return {
      status,
      errorMessage: response.errorMessage,
      data: response,
    };
  } catch (error) {
    throw new Error(error + "RVFCF");
  }
};


export const Finalizar = async (idCliente, formaPagamento) => {
  try {

    const dados = {
      idOrigem,
      idCliente: idCliente,
      formaPagamento: formaPagamento,
    }

    const { data: response, status } = await api.post(`/CarrinhoSeguroViagem/Finalizar?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`);

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R1Q98");
  }
};
