import { useState, useEffect } from "react";
import { Grid, useTheme, Typography, Card } from "@mui/material";
import useStyle from "./style";
import InfoIcon from "@mui/icons-material/Info";
import TiposLiquidacao from "../../util/typesTipoLiquidacao";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { Subtitulo, Titulo } from "../";
import Pix from "./Pix";
import OperacoesBancarias from "./OperacoesBancarias";
import Hand from "../../assets/images/hand.png";
import TiposPedido from "../../util/typesTipoPedido";
import { useQuery } from "react-query";
import { formatMoneyInString } from "../../util/helpers";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import AnexarComprovante from "../AnexarComprovante";
import { useAuth } from "../../hooks/AuthContext";
import { PEDIDOCOMPROVANTES } from "../../util/typesReactQuery";
import { getComprovantePedidoByIDPedido } from "../../api/Cambio";

const PedidoConcluidoPagamentoEscolhidoSeguroViagem = ({
  pedido,
  setErrorMessage,
  isTodosDetalhes = true,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const { user } = useAuth();
  const [formaPagamento, setFormaPagamento] = useState([
    { ...pedido.formaPagamento },
  ]);

  return (
    <>
      <Grid
        md={12}
        sm={10}
        lg={10}
        container
        className={classes.Container}
        justifyContent="center"
        alignItems="center"
      >
        {isTodosDetalhes && (
          <Grid item md={6} sm={12} pl={4}>
            <img width={65} src={Hand} />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Recebemos seu pedido
            </Typography>
            <Titulo sx={{ fontWeight: "bold", fontSize: 28 }}>
              <>
                Estamos aguardando o <br />
                pagamento de:
              </>
            </Titulo>
            <br />

            <Titulo color={"primary"} sx={{ fontWeight: "bold", fontSize: 30 }}>
              {formatMoneyInString(pedido?.valorTotalMoedaNacional)}
            </Titulo>

            <br />

            <Subtitulo sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <InfoIcon color="primary" sx={{ mr: theme.spacing(2) }} /> Você
              receberá atualizações do seu pedido por e-mail e pela plataforma
            </Subtitulo>

            <Grid sx={{ mt: 3 }} item md={isTodosDetalhes ? 4 : 12} sm={8}>
              {pedido.formaPagamento.tipoLiquidacao.textId === "PIX" ? (
                <Pix pedido={pedido} />
              ) : (
                <>
                  <OperacoesBancarias
                    formaPagamento={formaPagamento}
                    isTodosDetalhes={isTodosDetalhes}
                    isClienteVendendo={false}
                  />
                </>
              )}
            </Grid>
          </Grid>
        )}

        <Grid sx={{ mt: 3 }} item md={isTodosDetalhes ? 4 : 12} sm={8}>
          {pedido.formaPagamento.tipoLiquidacao.textId === "PIX" ? (
            <Pix pedido={pedido} />
          ) : (
            <>
              <OperacoesBancarias
                formaPagamento={formaPagamento}
                isTodosDetalhes={isTodosDetalhes}
                isClienteVendendo={false}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PedidoConcluidoPagamentoEscolhidoSeguroViagem;
