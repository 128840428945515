import React, { Fragment } from 'react';
import { Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { Modal } from "..";
import { useSeguro } from "../../store/seguroViagem";
import theme from "../Paleta/theme";
import { formatMoneyInString } from "../../util/helpers";  



export default function SeguroDetalhesPacote({ isValorVisible = false, showModal, handlerClose, setShowModal, detalhes }) {

      const { infoSeguro } = useSeguro();


      return (
            <Modal
                  sx={{
                        width: "100%", [theme.breakpoints.up("sm")]: {
                              width: "50%"
                        }
                  }
                  }
                  show={showModal}
                  hasButtonClose={true}
                  close={handlerClose()}
            >
                  <Typography
                        variant="h6"
                        sx={{
                              fontWeight: "bold",
                              borderBottom: "1px solid #ccc",
                              pb: 3,
                              pt: 2,
                        }}
                  >
                        {infoSeguro.pacoteSelecionado?.nomeProduto}
                  </Typography>
                  <Grid
                        container
                        lg={12}
                       
                  >
                        <Grid lg={12}>


                              {
                                 
                                          detalhes?.length > 0 ?
                                          detalhes.map((item, index) => (
                                                <Fragment key={index}>
                                                      {!!item?.detalhe?.length &&
                                                            <Typography sx={{ mb: .5, mt: .5 }}>
                                                                  {item?.valorCobertura?.toUpperCase() === "INCLUIDO" ?
                                                                        <>
                                                                              <CheckIcon color="success" sx={{ fontSize: 12, mr: 1 }} />
                                                                              {`${item?.detalhe} ${item?.valorCobertura}`}
                                                                        </>
                                                                        :
                                                                        <b>{item?.detalhe}</b>

                                                                  }

                                                            </Typography>
                                                      }

                                                      {item?.valorCobertura?.toUpperCase() !== "INCLUIDO" && !!item?.valorCobertura?.length &&
                                                            <Typography sx={{ mb: .5, mt: !!item?.detalhe?.length ? 0 : .5 }}>

                                                                  {item?.valorCobertura}

                                                            </Typography>
                                                      }

                                                      <Divider />
                                                </Fragment>
                                          ))
                                          :

                                          Array.from({ length: 6 }, (_, index) => (
                                                <Skeleton key={index} height={50} animation="wave" width={'100%'} />
                                          ))

                              }

                        </Grid>
                        {isValorVisible &&
                              <Grid lg={12} md={12}>
                                    <Typography sx={{ textAlign: "end" }}>Valor final</Typography>
                                    <Box
                                          sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "end",
                                                mb: 3,
                                          }}
                                    >

                                          <Typography
                                                onClick={() => setShowModal(!showModal)}
                                                sx={{
                                                      cursor: "pointer",
                                                      fontWeight: "bold",
                                                      ml: 1,
                                                      fontSize: 31,
                                                }}
                                                color="text"
                                          >
                                                {formatMoneyInString(infoSeguro.pacoteSelecionado?.valorTotal)}
                                          </Typography>
                                    </Box>
                              </Grid>

                        }

                  </Grid>


            </Modal>
      );
}