import { useEffect, useState } from "react";
import {
  Grid,
  Button,
  ListItemButton,
  ListItemIcon,
  Typography,
  useTheme,
  Box,
  Paper,
} from "@mui/material";
import PersonPinCircleRoundedIcon from "@mui/icons-material/PersonPinCircleRounded";
import { ButtonCustom } from "../Botoes/Button";
import Titulo from "../Textos/Titulo";
import useAlert from "../../hooks/useAlert";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Modal from "../Modal/Modal";
import { useQuery, useMutation, useQueryClient } from "react-query";
import CheckIcon from '@mui/icons-material/Check';

import { EnderecoItem } from "../";
import { CLIENTEENDERECOS } from "../../util/typesReactQuery";
import useStyles from "./styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  desativaAtivaByID,
  saveEndereco,
  getEnderecos,
} from "../../api/Endereco";
import { useAuth } from "../../hooks/AuthContext";
import BasicModal from "../Modal/Modal";

export default function ClienteEndereco({
  isSedex = false,
  setSelectedID,
  idCliente,
  isOperacao = false,
  isLoading = false,
  handlerNext,
  regiaoEntregaSelecionada = null,
  handlerChangeSedex,
  enderecoItemSelecionado,
  sedexSelecionado,
}) {
  const queryClient = useQueryClient();
  const { Alert, showAlertSuccess, showAlertError, showAlertInfo } = useAlert();
  const classes = useStyles();
  const { user } = useAuth();
  const enderecoDefault = {
    id: 0,
    idCliente: idCliente,
    cep: "",
    cidade: "",
    bairro: "",
    logradouro: "",
    numero: "",
    complemento: "",
    uf: "",
    idUf: 0,
    idTipoEndereco: 1,
  };
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [enderecoModal, setEnderecoModal] = useState();
  const [enderecoSelected, setEnderecoSelected] = useState();
  const [enderecoFiltro, setEnderecoFiltro] = useState([]);
  const [selectedEnderecoSedex, setSelectedEnderecoSedex] = useState(0);
  const [textError, setTextError] = useState("");
  const [textSuccess, setTextSuccess] = useState("");

  const mutationSaveEndereco = useMutation(saveEndereco, {
    //função chamada quando concluido a chamada da api
    onSuccess: (response) => {
      if (response.errorMessage && response.errorMessage.length > 0) {
        showAlertError(response.errorMessage);
      } else {
        showAlertSuccess("Oba! Seu endereço foi cadastrado com sucesso");
        setTimeout(() => {
          if (openModal) setOpenModal(false);
        }, 600);
      }
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      queryClient.invalidateQueries(CLIENTEENDERECOS);
    },
  });

  const mutationDesativa = useMutation(desativaAtivaByID, {
    //função chamada quando concluido a chamada da api
    onSuccess: (response) => {
      if (response.errorMessage && response.errorMessage.length > 0) {
        showAlertError(response.errorMessage);
      } else {
        showAlertSuccess("O status de seu endereço foi alterado");
      }
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      queryClient.invalidateQueries(CLIENTEENDERECOS);
    },
  });

  const handlerChangeEnderecoEntrega = (idEndereco) => () => {
    if (isOperacao) setSelectedID(idEndereco);
  };

  const handlerSubmitSalvarEndereco = async () => {
    try {

      mutationSaveEndereco.mutate(enderecoSelected);

    } catch (error) {
      showAlertError("Ops,algo deu errado: ", error.message);
    }
  };

  const handlerCloseModal = (e) => {
    setOpenModal(false);
  };

  const handlerEditarEndereco = (endereco) => (event) => {
    setEnderecoModal(endereco);
    setOpenModal(true);
  };

  const {
    data: enderecosCliente,
    isLoading: isLoadingEnderecosCliente,
    refetch: refetchEnderecosCliente,
  } = useQuery(CLIENTEENDERECOS, () => getEnderecos(idCliente), {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const desativaAtiva = (id) => (event) => {
    event.preventDefault();
    if (id < 0) {
      showAlertError(
        "Ahh! Infelizmente não conseguimos identificar o seu endereço, por gentileza tente mais tarde  - R2AD9"
      );
      return;
    }

    let enderecoSelecionado = {
      idCliente: user.id,
      idEndereco: id,
    };
    mutationDesativa.mutate(enderecoSelecionado);
    // showAlertInfo("Ainda em implementação");
  };


  const handlerSelectedSedex = (event, endereco) => {
    setSelectedEnderecoSedex(endereco.id)
  }


  const ListIsOperacao = ({ endereco }) => {
    return (
      <>
        <ListItem
          sx={{ padding: 0 }}
          className={
            enderecoItemSelecionado?.id === endereco?.id ? [classes.checked] :
              sedexSelecionado?.id === endereco.id ? [classes.checked] :
                []
          }
          key={endereco?.id}
        >
          <ListItemButton
            sx={{
              pl: 1,
            }}
            onClick={
              isSedex
                ? (event) => handlerChangeSedex(event, endereco)
                : (event) => handlerNext(event, endereco)
            }
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <HomeWorkIcon />
            </ListItemIcon>
            <ListItemText
              multiline
              id={endereco?.id}
              primary={endereco.cidade}
              style={{ fontWeight: "bold", minWidth: "60px" }}
            />
            <List sx={{ width: "15em" }}>
              <ListItemText
                sx={{ fontSize: 12 }}
                primary={
                  <Typography sx={{ fontSize: 13 }}> Endereço</Typography>
                }
                secondary={
                  <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
                    {" "}
                    {endereco.logradouro} {endereco.numero}
                  </Typography>
                }
              />
              <ListItemText
                primary={
                  <Typography sx={{ fontSize: 13 }}> Endereço</Typography>
                }
                secondary={
                  <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
                    {endereco.bairro}
                  </Typography>
                }
              />
            </List>

            {
              (enderecoItemSelecionado?.id === endereco?.id) ||
                (sedexSelecionado?.id === endereco.id) ?
                <CheckIcon
                  className={classes.iconCheck}
                  sx={{ fontSize: 14, marginLeft: 2 }}

                />
                :
                <ArrowForwardIosIcon
                  sx={{ color: "#444", fontSize: 14, marginLeft: 2 }}
                />


            }


          </ListItemButton>
        </ListItem>
      </>
    );
  };

  const ListIsNotOperacao = ({ endereco }) => {
    return (
      <>
        <ListItem
          className={classes.List}
          sx={{
            mt: 3,
            boxShadow: "1px 1px 8px 4px  #efefef",
            border: "none",
            display: "block",
            p: 2,
            ml: 1,
            mr: 2,
          }}
          key={endereco.id}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <Grid>
              {" "}
              <ListItemIcon>
                <HomeWorkIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                multiline
                id={endereco?.id}
                primary={endereco.cidade}
                style={{ fontWeight: "bold", flexBasis: "50%" }}
              />
              <List sx={{ flexBasis: "25%" }}>
                <ListItemText
                  primary={"Endereço"}
                  secondary={`${endereco.logradouro}-${endereco.numero}`}
                />
                <ListItemText
                  primary={"Bairro"}
                  secondary={`${endereco.bairro}`}
                />
              </List>
              <Button
                sx={{ flexBasis: "25%" }}
                component="label"
                size="medium"
                onClick={desativaAtiva(endereco.id)}
              >
                {endereco.ativo ? "Desativar" : "Ativar"}
              </Button>
            </Grid>
          </Grid>
        </ListItem>
      </>
    );
  };

  useEffect(() => {
    if (!isSedex && isOperacao && enderecosCliente?.length > 0) {
      let enderecoFiltrado = enderecosCliente.filter(
        (e) => e.idUf == regiaoEntregaSelecionada?.uf?.id
      );
      setEnderecoFiltro(enderecoFiltrado);
    } else setEnderecoFiltro(enderecosCliente);
  }, [enderecosCliente]);

  return (
    <>
      <Alert />
      <BasicModal show={openModal} error={textError} successMessage={textSuccess} close={handlerCloseModal}>
        <Grid sx={{ m: 2 }}>
          <Titulo
            sx={{ mb: 6, fontSize: 21 }}
            label="Cadastro de novo endereço"
          ></Titulo>
          <EnderecoItem
            isEdit={false}
            setTextError={setTextError}
            setTextSuccess={setTextSuccess}
            data={enderecoModal} setData={setEnderecoSelected} />
          <Grid className={classes.Grid}>
            <ButtonCustom onClick={handlerSubmitSalvarEndereco}>
              Salvar
            </ButtonCustom>
          </Grid>
        </Grid>
      </BasicModal>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <ButtonCustom
          size="small"
          variant="contained"
          style={{
            width: "auto",
            cursor: "pointer",

            textAlign: "end",
          }}
          onClick={handlerEditarEndereco(enderecoDefault)}
        >
          {`+ Adicionar Novo Endereço`}
        </ButtonCustom>
      </Grid>

      <List
        dense
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "background.paper",
          position: "relative",
          paddingRight: "8px",
        }}
      >
        {!isLoading && enderecosCliente?.length > 0 ? (
          isOperacao ? (
            enderecoFiltro?.length > 0 &&
              enderecoFiltro.some((e) => e.ativo === true) ? (
              enderecoFiltro.map((endereco) => {
                return (
                  endereco.id > 0 &&
                  endereco.ativo && <ListIsOperacao endereco={endereco} />
                );
              })
            ) : (
              <Paper
                sx={{ padding: 2, backgroundColor: "rgb(18, 18, 18,0.1)" }}
              >
                <Typography>
                  Você não possui endereço cadastrado para a região de{" "}
                  {regiaoEntregaSelecionada?.uf?.descricao}
                </Typography>
              </Paper>
            )
          ) : (
            enderecosCliente.map((endereco) => {
              return (
                endereco.id > 0 && <ListIsNotOperacao endereco={endereco} />
              );
            })
          )
        ) : (
          <>
            <Box sx={{ margin: 'auto', textAlign: "center", mt: 4 }}>
              <PersonPinCircleRoundedIcon
                color="primary"
                sx={{ fontSize: 40, margin: "auto" }}
              ></PersonPinCircleRoundedIcon>

              <Typography sx={{ fontSize: 17 }}>
                Nenhum endereço encontrado
              </Typography>
            </Box>
          </>
        )}
      </List>
    </>
  );
}
