import { Theme } from "@mui/material/styles";
import { IVariable } from "../interfaces/IVariable";
import {
  variables as VariablesDebug,
  template as TemplateDebug,
} from "./debug";

import {
  variables as VariablesQuatroCantos,
  template as TemplateQuatroCantos,
} from "./quatrocantos";

import {
  variables as VariablesDgCambio,
  template as TemplateDgCambio,
} from "./dgcambio";

import {
  variables as VariablesDuoDigital,
  template as TemplateDuoDigital,
} from "./duodigital";

import {
  variables as VariablesLabor,
  template as TemplateLabor,
} from "./labor";

import { variables as VariablesVips, template as TemplateVips } from "./vips";

import {
  variables as VariablesExecutive,
  template as TemplateExecutive,
} from "./executive";

import {
  variables as VariablesLevyCam,
  template as TemplateLevyCam,
} from "./levycam";

import {
  variables as VariablesUnida,
  template as TemplateUnida,
} from "./unida";

import {
  variables as VariableBrokerBrasil,
  template as TemplateBrokerBrasil,
} from "./brokerbrasil";

import { variables as VariableFair, template as TemplateFair } from "./fair";

import {
  variables as VariableFourTrade,
  template as TemplateFourTrade,
} from "./fourtrade";

import {
  variables as VariableGetMoney,
  template as TemplateGetMoney,
} from "./getmoney";

import { variables as VariableFram, template as TemplateFram } from "./fram";

import {
  variables as VariableSendMoney,
  template as TemplateSendMoney,
} from "./sendmoney";

import {
  variables as VariableLuminaCorretora,
  template as TemplateLuminaCorretora,
} from "./luminacorretora";

import {
  variables as VariableCambionet,
  template as TemplateCambionet,
} from "./cambionet";

import {
  variables as VariablePrimecase,
  template as TemplatePrimecase,
} from "./primecase";

import {
  variables as VariableOuroRapido,
  template as TemplateOuroRapido,
} from "./HOuroRapido";

import {
  variables as VariableUnidaOuro,
  template as TemplateUnidaOuro,
} from "./HUnidaOuro";

import {
  variables as VariableUnidaCambio,
  template as TemplateUnidaCambio,
} from "./HUnidaCambio";

import {
  variables as VariablePayerMax,
  template as TemplatePayerMax,
} from "./HPayerMax";

let configurationVariables: IVariable;
let configurationTheme: Theme;

switch (process.env.REACT_APP_LOJA) {
  case "duodigital":
    configurationVariables = VariablesDuoDigital;
    configurationTheme = TemplateDuoDigital;
    break;
  case "executive":
    configurationVariables = VariablesExecutive;
    configurationTheme = TemplateExecutive;
    break;
  case "levycam":
    configurationVariables = VariablesLevyCam;
    configurationTheme = TemplateLevyCam;
    break;
  case "unida":
    configurationVariables = VariablesUnida;
    configurationTheme = TemplateUnida;
    break;
  case "brokerbrasil":
    configurationVariables = VariableBrokerBrasil;
    configurationTheme = TemplateBrokerBrasil;
    break;
  case "fair":
    configurationVariables = VariableFair;
    configurationTheme = TemplateFair;
    break;
  case "fourtrade":
    configurationVariables = VariableFourTrade;
    configurationTheme = TemplateFourTrade;
    break;
  case "getmoney":
    configurationVariables = VariableGetMoney;
    configurationTheme = TemplateGetMoney;
    break;
  case "fram":
    configurationVariables = VariableFram;
    configurationTheme = TemplateFram;
    break;
  case "sendmoney":
    configurationVariables = VariableSendMoney;
    configurationTheme = TemplateSendMoney;
    break;
  case "labor":
    configurationVariables = VariablesLabor;
    configurationTheme = TemplateLabor;
    break;
  case "vips":
    configurationVariables = VariablesVips;
    configurationTheme = TemplateVips;
    break;
  case "quatrocantos":
    configurationVariables = VariablesQuatroCantos;
    configurationTheme = TemplateQuatroCantos;
    break;
  case "dgcambio":
    configurationVariables = VariablesDgCambio;
    configurationTheme = TemplateDgCambio;
    break;
  case "luminacorretora":
    configurationVariables = VariableLuminaCorretora;
    configurationTheme = TemplateLuminaCorretora;
    break;
  case "cambionet":
    configurationVariables = VariableCambionet;
    configurationTheme = TemplateCambionet;
    break;
  case "debug":
    configurationVariables = VariablesDebug;
    configurationTheme = TemplateDebug;
    break;
  case "primecase":
    configurationVariables = VariablePrimecase;
    configurationTheme = TemplatePrimecase;
    break;
  case "ourorapido":
    configurationVariables = VariableOuroRapido;
    configurationTheme = TemplateOuroRapido;
    break;
  case "unidaouro":
    configurationVariables = VariableUnidaOuro;
    configurationTheme = TemplateUnidaOuro;
    break;
  case "unidacambio":
    configurationVariables = VariableUnidaCambio;
    configurationTheme = TemplateUnidaCambio;
    break;
    case "payermax":
      configurationVariables = VariablePayerMax;
      configurationTheme = TemplatePayerMax;
      break;
  default:
    configurationVariables = VariablesDuoDigital;
    configurationTheme = TemplateDuoDigital;
    break;
}

export { configurationVariables, configurationTheme };
