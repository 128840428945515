import { useState, useEffect } from "react";
import { Grid, useTheme, Typography, Card } from "@mui/material";
import useStyle from "./style";
import InfoIcon from "@mui/icons-material/Info";
import TiposLiquidacao from "../../util/typesTipoLiquidacao";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { Subtitulo, Titulo } from "../";
import Pix from "./Pix";
import OperacoesBancarias from "./OperacoesBancarias";
import Hand from "../../assets/images/hand.png";
import TiposPedido from "../../util/typesTipoPedido";
import { useQuery } from "react-query";
import { GetCanalBancarioExteriorByIDMoeda } from "../../api/FormaPagamento";
import { CANALBANCARIOEXTERIOR } from "../../util/typesReactQuery";
import { formatMoneyInString } from "../../util/helpers";
import OperacaoBancarioRecebimento from "./OperacaoBancarioRecebimento";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import AnexarComprovante from "../AnexarComprovante";
import { useAuth } from "../../hooks/AuthContext";
import { PEDIDOCOMPROVANTES } from "../../util/typesReactQuery";
import { getComprovantePedidoByIDPedido } from "../../api/Cambio";

const PedidoConcluidoPagamentoEscolhido = ({
  pedido,
  setErrorMessage,
  isTodosDetalhes = true,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const { user } = useAuth();
  const [isRecebimento, setIsRecebimento] = useState(false);
  const [formaPagamento, setFormaPagamento] = useState([
    { ...pedido.formaPagamento },
  ]);

  const {
    data: comprovantes,
    isLoading: isLoadingComprovantes,
    refetch: refetchComprovantes,
  } = useQuery(
    PEDIDOCOMPROVANTES,
    () => getComprovantePedidoByIDPedido(user.id, pedido?.id),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch } = useQuery(
    CANALBANCARIOEXTERIOR,
    () => GetCanalBancarioExteriorByIDMoeda(pedido.itens[0].moeda.id),
    {
      onSuccess: (data) => {
        if (!data || !!data.errorMessage)
          setErrorMessage(
            `Ops, algo deu errado, por favor informe o suporte o seguinte error: Não foi possivel encontrar nenhum canal exterior - RAF4E `
          );

        setFormaPagamento(data.data);
      },
      onError: (error) => {
        setErrorMessage(
          `Ops, algo deu errado, por favor informe o suporte o seguinte error: ${error} - R34C8 `
        );
      },
      enabled: false,
    }
  );

  useEffect(() => {
    refetchComprovantes();
    if (pedido.tipoPedido.id === TiposPedido.Recebimento) {
      setIsRecebimento(true);
      refetch();
      return;
    }
  }, [pedido]);

  return (
    <>
      <Grid
        md={12}
        sm={10}
        lg={10}
        container
        className={classes.Container}
        justifyContent="center"
        alignItems="center"
      >
        {isTodosDetalhes && (
          <Grid item md={6} sm={12} pl={4}>
            <img width={65} src={Hand} />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Recebemos seu pedido
            </Typography>
            <Titulo sx={{ fontWeight: "bold", fontSize: 28 }}>
              {pedido.tipoPedido.id === TiposPedido.Recebimento && (
                <>
                  Estamos processando sua <br />
                  ordem de pagamento no valor de:
                </>
              )}

              {(pedido.tipoPedido.id === TiposPedido.VendaEspecie ||
                pedido.tipoPedido.id === TiposPedido.Remessa) && (
                <>
                  Estamos aguardando o <br />
                  pagamento de:
                </>
              )}

              {pedido.tipoPedido.id === TiposPedido.CompraEspecie && (
                <>
                  Estamos processando seu pedido <br />
                  Valor final que irá receber é de:
                </>
              )}
            </Titulo>
            <br />

            <Titulo color={"primary"} sx={{ fontWeight: "bold", fontSize: 30 }}>
              {formatMoneyInString(pedido?.valorTotalMoedaNacional)}
            </Titulo>

            <br />

            {isRecebimento && (
              <Subtitulo sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <InfoIcon color="primary" sx={{ mr: theme.spacing(2) }} />
                Numero da Ordem: {pedido?.ordemPagamento?.codigoOrdem}
              </Subtitulo>
            )}

            <Subtitulo sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <InfoIcon color="primary" sx={{ mr: theme.spacing(2) }} /> Você
              receberá atualizações do seu pedido por e-mail e pela plataforma
            </Subtitulo>
            {pedido.formaPagamento.tipoLiquidacao.textId !== "PIX" &&
              pedido.tipoPedido.id !== TiposPedido.CompraEspecie &&  pedido.tipoPedido.id !== TiposPedido.Recebimento &&(
                <div className={classes.divAnexo}>
                  <hr style={{ marginTop: "2rem", marginBottom: "2rem" }} />
                  <Typography sx={{ ml: 1 }} className={classes.Titulo}>
                    <FilePresentIcon
                      color="black"
                      fontSize="10"
                      sx={{ mr: 1 }}
                    />
                    Comprovantes de pagamento
                  </Typography>

                  <AnexarComprovante
                    loading={false}
                    documentos={comprovantes}
                    idPedido={pedido?.id}
                    userID={user.id}
                    permiteUpload={pedido?.status && pedido?.status.id === 1}
                  />
                </div>
              )}
          </Grid>
        )}

        <Grid sx={{ mt: 3 }} item md={isTodosDetalhes ? 4 : 12} sm={8}>
          {pedido.formaPagamento.tipoLiquidacao.textId === "PIX" ? (
            <Pix pedido={pedido} />
          ) : isRecebimento ? (
            <OperacaoBancarioRecebimento
              dadosBancarios={pedido?.clienteDadosBancarios}
            />
          ) : (
            <>
              <OperacoesBancarias
                formaPagamento={formaPagamento}
                isTodosDetalhes={isTodosDetalhes}
                isClienteVendendo={
                  pedido.tipoPedido.id === TiposPedido.compraEspecie
                }
              />

              {pedido.tipoPedido.id === TiposPedido.CompraEspecie && (
                <OperacaoBancarioRecebimento
                  dadosBancarios={pedido?.clienteDadosBancarios}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PedidoConcluidoPagamentoEscolhido;
