import { useState, useEffect, Fragment } from "react";
import Skeleton from "@mui/material/Skeleton";
import TiposPedido from "../../util/typesTipoPedido";
import AnexarComprovante from "../AnexarComprovante";
import { Grid, Typography, Box, Divider, Badge } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ListItemText from "@mui/material/ListItemText";
import Modal from "../Modal/Modal";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { useQuery } from "react-query";
import { PEDIDOCOMPROVANTES } from "../../util/typesReactQuery";
import { useAuth } from "../../hooks/AuthContext";
import useAlert from "../../hooks/useAlert";
import useStyles from "./styles";
import { getComprovantePedidoByIDPedido } from "../../api/Cambio";
import CancelarPedidoCambio from "../CancelaPedidoCambio";
import EdicaoFormaPagamento from "../EdicaoFormaPagamento";
import FormasPagamentoSelecionado from "../PedidoConcluidoPagamentoEscolhidoSeguroViagem";
import TipoStatusPedido from "../../util/typesTipoStatusPedido";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { format } from "date-fns";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import Delivery from "../Delivery";
import { formatMoneyInStringForm } from "../../util/helpers";
import DadosEntregaPedido from "../DadosEntregaPedido";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function DetalhePedidoSeguroViagem({ dadosPedido, isLoading }) {
  // seta fixamente
  const { user } = useAuth();
  const { Alert, showAlertSuccess, showAlertError } = useAlert();
  const classes = useStyles();
  const [openModalPagamento, setOpenModalPagamento] = useState(false);

  const handlerOpenModalPagamento = (e) => {
    setOpenModalPagamento(true);
  };

  const handlerCloseModalPagamento = (e) => {
    setOpenModalPagamento(false);
  };

  return (
    <>
      <Alert />
      <Grid item xs={12}>
        <Grid>
          {isLoading && <Skeleton width="auto" height={500} />}

          {!isLoading && (
            <List sx={{ width: "100%" }}>
              <Box className={classes.Detalhes}>
                <Box className={classes.TitleBox}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 1,
                      fontWeight: "bold",
                    }}
                  >
                    DETALHES DO PEDIDO
                    <span className={classes.Codigo}> #{dadosPedido?.id}</span>
                    <ListItem
                      secondaryAction={
                        <ListItemText
                          primary={format(
                            new Date(dadosPedido?.dataCadastro),
                            "dd/MM/yyyy"
                          )}
                        />
                      }
                      disablePadding
                    >
                      <ListItemText className={classes.Item}>
                        Data do pedido:
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <ListItemText
                          primary={dadosPedido?.seguroViagemDestino.descricao}
                        />
                      }
                      disablePadding
                    >
                      <ListItemText className={classes.Item}>
                        Destino:
                      </ListItemText>
                    </ListItem>
                  </Typography>
                </Box>
                {dadosPedido?.status.id === 1 && (
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CancelarPedidoCambio
                      idPedido={dadosPedido?.id}
                      idCliente={user.id}
                    />
                  </Box>
                )}
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid sx={{ width: "100%" }} item lg={6}>
                    <Item>
                      <Box className={classes.ItensPedido}>
                        <Typography className={classes.Titulo}>
                          Plano
                        </Typography>

                        <Divider sx={{ mt: 1 }} />

                        <Fragment>
                          <List
                            sx={{
                              width: "100%",
                            }}
                          >
                            <ListItem
                              secondaryAction={<ListItemText />}
                              disablePadding
                            >
                              <ListItem
                                secondaryAction={
                                  <ListItemText
                                    primary={dadosPedido.seguroViagem.codigo}
                                  />
                                }
                                disablePadding
                              >
                                <ListItemText className={classes.Item}>
                                  Código:
                                </ListItemText>
                              </ListItem>
                            </ListItem>

                            <ListItem
                              secondaryAction={<ListItemText />}
                              disablePadding
                            >
                              <ListItem
                                secondaryAction={
                                  <ListItemText
                                    primary={dadosPedido.seguroViagem.nome}
                                  />
                                }
                                disablePadding
                              >
                                <ListItemText className={classes.Item}>
                                  Nome:
                                </ListItemText>
                              </ListItem>
                            </ListItem>
                            <ListItem
                              secondaryAction={
                                <ListItemText
                                  primary={"R$ " + dadosPedido?.valorTotal}
                                />
                              }
                              disablePadding
                            >
                              <ListItemText className={classes.Item}>
                                Valor total:
                              </ListItemText>
                            </ListItem>
                            <ListItem
                              secondaryAction={
                                <ListItemText
                                  primary={
                                    "R$ " + dadosPedido?.valorAssistencia
                                  }
                                />
                              }
                              disablePadding
                            >
                              <ListItemText className={classes.Item}>
                                Valor assistência:
                              </ListItemText>
                            </ListItem>
                            <ListItem
                              secondaryAction={<ListItemText />}
                              disablePadding
                            >
                              <ListItem
                                secondaryAction={
                                  <ListItemText
                                    primary={dadosPedido.quantidadeDias}
                                  />
                                }
                                disablePadding
                              >
                                <ListItemText className={classes.Item}>
                                  Quantidade dias:
                                </ListItemText>
                              </ListItem>
                            </ListItem>

                            {dadosPedido.seguroViagem.informacoesAdicionais !==
                              null && (
                              <ListItem
                                secondaryAction={<ListItemText />}
                                disablePadding
                              >
                                <ListItem
                                  secondaryAction={
                                    <ListItemText
                                      primary={
                                        dadosPedido.seguroViagem
                                          .informacoesAdicionais
                                      }
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemText className={classes.Item}>
                                    Informações adicionais:
                                  </ListItemText>
                                </ListItem>
                              </ListItem>
                            )}
                          </List>
                          <Divider sx={{ mt: 1 }} />
                        </Fragment>
                      </Box>
                    </Item>
                  </Grid>
                  <Grid sx={{ width: "100%" }} item lg={6}>
                    <Item>
                      <Box className={classes.ItensPedido}>
                        <Typography className={classes.Titulo}>
                          Passageiros{" "}
                          <Badge
                            sx={{ ml: 2 }}
                            badgeContent={
                              dadosPedido && dadosPedido?.passageiros?.length
                            }
                            color="primary"
                          />
                        </Typography>

                        <Divider sx={{ mt: 1 }} />
                        {dadosPedido &&
                          dadosPedido?.passageiros?.map((passageiro, index) => (
                            <Fragment key={passageiro.id + index}>
                              <List
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <ListItem
                                  secondaryAction={<ListItemText />}
                                  disablePadding
                                >
                                  <ListItem
                                    secondaryAction={
                                      <ListItemText primary={passageiro.nome} />
                                    }
                                    disablePadding
                                  >
                                    <ListItemText className={classes.Item}>
                                      Nome:
                                    </ListItemText>
                                  </ListItem>
                                </ListItem>

                                <ListItem
                                  secondaryAction={<ListItemText />}
                                  disablePadding
                                >
                                  <ListItem
                                    secondaryAction={
                                      <ListItemText
                                        primary={format(
                                          new Date(passageiro.dataNascimento),
                                          "dd/MM/yyyy"
                                        )}
                                      />
                                    }
                                    disablePadding
                                  >
                                    <ListItemText className={classes.Item}>
                                      Data de nascimento:
                                    </ListItemText>
                                  </ListItem>
                                </ListItem>

                                {passageiro.numeroDocumentoIdentificacao !==
                                  null && (
                                  <ListItem
                                    secondaryAction={<ListItemText />}
                                    disablePadding
                                  >
                                    <ListItem
                                      secondaryAction={
                                        <ListItemText
                                          primary={
                                            passageiro.numeroDocumentoIdentificacao
                                          }
                                        />
                                      }
                                      disablePadding
                                    >
                                      <ListItemText className={classes.Item}>
                                        Nr. documento:
                                      </ListItemText>
                                    </ListItem>
                                  </ListItem>
                                )}
                                {passageiro.telefone !== null && (
                                  <ListItem
                                    secondaryAction={<ListItemText />}
                                    disablePadding
                                  >
                                    <ListItem
                                      secondaryAction={
                                        <ListItemText
                                          primary={passageiro.telefone}
                                        />
                                      }
                                      disablePadding
                                    >
                                      <ListItemText className={classes.Item}>
                                        Telefone:
                                      </ListItemText>
                                    </ListItem>
                                  </ListItem>
                                )}
                              </List>
                              <Divider sx={{ mt: 1 }} />
                            </Fragment>
                          ))}
                      </Box>
                    </Item>

                    <Item>
                      <List className={classes.formaPagamento}>
                        <Typography className={classes.Titulo}>
                          Forma de pagamento
                        </Typography>
                        <Divider sx={{ mt: 1 }}></Divider>
                        <ListItem>
                          <ListItemText>
                            <FormasPagamentoSelecionado
                              pedido={dadosPedido}
                              isTodosDetalhes={false}
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </List>
          )}
        </Grid>
      </Grid>
    </>
  );
}
