import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonAcaoGrande from "../../../Botoes/ButtonAcaoGrande";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import Modal from "../../../Modal/Modal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Style } from "./styles";
import theme from "../../../Paleta/theme";
import CheckIcon from '@mui/icons-material/Check';
import { formatMoneyInString } from "../../../../util/helpers";
import { useSeguro } from "../../../../store/seguroViagem";
import useAlert from "../../../../hooks/useAlert";
import { addSeguroViagem, getCoberturas } from "../../../../api/Carrinho/SeguroViagem";
import { useMutation } from "react-query";
import { Divider } from "@mui/material";
import SeguroDetalhesPacote from "../../../SeguroDetalhesPacote";



function Itens() {
  const navigation = useNavigate();
  const classes = Style();
  const [showModal, setShowModal] = useState(false);

  const { infoSeguro, updateSelectPacote } = useSeguro();
  const [isLoading, setIsLoading] = useState(false);
  const [seguros, setSeguros] = useState();
  const [detalhes, setDetalhesSeguros] = useState();
  const { Alert, showAlertError } = useAlert();


  const mutationGetCobertura = useMutation(getCoberturas, {
    onSuccess: (response) => {
      if (response?.errorMessage && response?.errorMessage?.length > 0) {
        showAlertError(`Ahh, algo deu Errado: ${response.errorMessage}`);
      }

      setDetalhesSeguros(response.data);


    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {

      showAlertError(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },

    onSettled: () => setIsLoading(false)
  });






  useEffect(() => {
    setSeguros(() => infoSeguro.pacotes)
  }, [infoSeguro.pacotes]);

  const handlerComprarPacote = async (pacote = null) => {


    if (!pacote) {
      showAlertError("Ops, é necessario escolher um pacote");
      return;
    }

    const response = await addSeguroViagem(infoSeguro, pacote);
    updateSelectPacote(pacote)

    if (!!response?.errorMessage) {
      showAlertError(`Ops, algo deu errado: ${response.errorMessage} - R06JF`);
      return;
    }

    navigation("/SeguroViagem/Passageiros");
  };

  const handlerSelectedSeguro = (pacote) => (event) => {
    setShowModal((state) => !state);
    updateSelectPacote(pacote);
    if (pacote) {
      setIsLoading(true);

      let dados = {
        ...infoSeguro,
        pacoteSelecionado: { ...pacote }
      };

      mutationGetCobertura.mutate(dados)
    }
  }


  return (
    <>
      <Alert />
      <Grid className={classes.Container}>
        <Grid
          sx={{
            margin: "auto",
            display: "flex",
            justifyContent: "space-between ",
            mb: 2,
            mt: 0,
          }}
          item
          xs={11}
          lg={10}
        >
          <Box className={classes.Titulo}>
            <WorkOutlinedIcon sx={{ mr: 2 }} />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Escolha seu Seguro Viagem e viaje mais tranquilo!

            </Typography>
          </Box>
        </Grid>
        {seguros &&
          seguros.map((pacote, index) => {
            return (
              <Fragment key={index}>
                <Grid
                  className={classes.Item}
                  sx={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "space-between ",
                  }}
                  container
                  xs={11}
                  lg={10}
                >
                  <Grid
                    container
                    item
                    lg={5}
                    md={12}
                    sm={12}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <div>
                      <Typography
                        sx={{
                          fontSize: 21,
                          fontWeight: "bold",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {pacote.nomeProduto}
                      </Typography>
                      <Typography color="text.secondary" sx={{ mt: 0 }}>
                        <CheckIcon className={classes.Check} />
                        Confira os detalhes para mais informações
                      </Typography>
                    </div>
                    <Box sx={{}}>
                      <Typography
                        onClick={handlerSelectedSeguro(pacote)}
                        className={classes.span}
                        color="text"
                      >
                        <span>
                          Ver mais{" "}
                          <ArrowForwardIosIcon sx={{ fontSize: 11 }} />
                        </span>
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid md={12} sm={12} lg={4} className={classes.Bloco}>
                    <Typography className={classes.ValorFinal}>
                      Valor final:
                    </Typography>
                    <Box className={classes.Valor}>
                      <Typography
                        onClick={() => setShowModal(!showModal)}
                        sx={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          ml: 1,
                          fontSize: 24,
                        }}
                        color="text"
                      >
                        {formatMoneyInString(pacote.valorTotal)}
                      </Typography>
                    </Box>
                    <ButtonAcaoGrande
                      Handle={() => handlerComprarPacote(pacote)}
                      texto="Contratar"
                      className={classes.buttonSearch}
                    />
                  </Grid>
                </Grid>
                <br></br>
              </Fragment>
            );
          })}
      </Grid>
      <Box display="flex" sx={{ maxWidth: '600px !important' }} justifyContent="start">
        {/* <Modal
          sx={{
            width: "100%", [theme.breakpoints.up("sm")]: {
              width: "50%"
            }
          }
          }
          show={showModal}
          hasButtonClose={true}
          close={handlerSelectedSeguro(null)}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              borderBottom: "1px solid #ccc",
              pb: 3,
              pt: 2,
            }}
          >
            {infoSeguro.pacoteSelecionado?.nomeProduto}
          </Typography>
          <Grid
            container
            lg={12}
            sx={{ mt: 3, borderBottom: "1px solid #ccc" }}
          >
            <Grid lg={12}>


              {
                detalhes?.length > 0 &&
                detalhes.map((item, index) => (
                  <Fragment key={index}>
                    {!!item?.detalhe?.length &&
                      <Typography sx={{ mb: .5, mt: .5 }}>
                        {item?.valorCobertura?.toUpperCase() === "INCLUIDO" ?
                          <>
                            <CheckIcon color="success" sx={{ fontSize: 12, mr: 1 }} />
                            {`${item?.detalhe} ${item?.valorCobertura}`}
                          </>
                          :
                          <b>{item?.detalhe}</b>

                        }

                      </Typography>
                    }

                    {item?.valorCobertura?.toUpperCase() !== "INCLUIDO" && !!item?.valorCobertura?.length &&
                      <Typography sx={{ mb: .5, mt: !!item?.detalhe?.length ? 0 : .5 }}>

                        {item?.valorCobertura}

                      </Typography>
                    }

                    <Divider />
                  </Fragment>
                ))
              }

            </Grid>
            <Grid lg={6} md={12}>
              <Typography sx={{ textAlign: "end" }}>Valor final</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  mb: 3,
                }}
              >

                <Typography
                  onClick={() => setShowModal(!showModal)}
                  sx={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    ml: 1,
                    fontSize: 31,
                  }}
                  color="text"
                >
                  {formatMoneyInString(infoSeguro.pacoteSelecionado?.valorTotal)}
                </Typography>
              </Box>
            </Grid>
          </Grid>


        </Modal> */}

        <SeguroDetalhesPacote isValorVisible={true} isLoading={isLoading} showModal={showModal} setShowModal={setShowModal} handlerClose={handlerSelectedSeguro} detalhes={detalhes} />
      </Box >
    </>
  );
}

export default Itens;
