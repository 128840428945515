import { useNavigate } from "react-router-dom";
import { Subtitulo, Timer, Titulo } from "../../../components";
import CarrinhoPage from "../../../components/CarrinhoPage";
import { useAuth } from "../../../hooks/AuthContext";
import { addPagamento } from "../../../api/Carrinho/TranferenciaInternacional";
import { useEffect, useState } from "react";
import useAlert from "../../../hooks/useAlert";
import useLojaFechada from "../../../hooks/useLojaFechada";
import { getlocalStorageTimerCarrinho } from "../../../util/LocalStorage";
import PassageiroItem from "../../../components/PassageiroItem";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useSeguro } from "../../../store/seguroViagem";
import { addPassageiros } from "../../../api/Carrinho/SeguroViagem";
import { useMutation } from "react-query";
import { verificaIdadeComData } from "../../../util/helpers";


export function Passageiros() {
  const navigation = useNavigate();
  const { user } = useAuth();
  const { Alert, showAlertError } = useAlert();
  const [loadingAddPagamento, setLoadingAddPagamento] = useState(false);
  const [passageiros, setPassageiros] = useState([]);

  const { infoSeguro } = useSeguro();

  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();


  const mutationSavePassageiro = useMutation(addPassageiros, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {

      if (!!data?.errorMessage)
        throw new Error(data.errorMessage, " - RNH6I");

      navigation("/SeguroViagem/Pagamento");

    },
    //função chamada quando ocorre um erro
    onError: async (err) => {
      showAlertError(`Ops, Algo deu errado:  ${err} - RMHD8 `);
    },
  });

  const handlerSavePassageiros = async () => {

    let isDevContinuar = true;

    infoSeguro.passageiros.forEach((cliente, index) => {
      let menssagem = verificaIdadeComData(cliente.dataNascimento, cliente.idade, cliente.nome);

      if (menssagem.length > 0) {
        showAlertError(menssagem);
        isDevContinuar = false;
      }
    }
    );
    if (isDevContinuar)
      await mutationSavePassageiro.mutate(infoSeguro);

  };

  const [dataLocal, setDataLocal] = useState(() =>
    getlocalStorageTimerCarrinho()
  );



  const steps = [
    {
      label: "Vitrine",
      description: "",
      link: "/SeguroViagem",
    },
    {
      label: "Passageiros",
      description: "",
      link: "/SeguroViagem/Passageiros",
    },
    {
      label: "Forma de pagamento",
      description: ``,
      link: "/SeguroViagem/Pagamento",
    },
    {
      label: "Finalizar",
      description: ``,
      link: "/SeguroViagem/ResumoSeguro",
    },
  ];

  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada);
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);



  const handleBack = () => {
    navigation("/SeguroViagem");
  };

  const handlerChangeDataPassgeiro = () => { };




  return (
    <div >
      <Alert />
      <CarrinhoPage
        handleBack={handleBack}
        disableButtonNext={false}
        activeStep={1}
        steps={steps}
        handleNext={handlerSavePassageiros}
      >

        <Grid>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Insira os dados dos Viajantes
          </Typography>
          {infoSeguro.passageiros &&
            infoSeguro.passageiros.map((item, i) => {
              return (
                <Box sx={{ mb: "1rem" }}>
                  <PassageiroItem
                    index={i}
                    key={i}
                    passageiro={item}
                    onChange={handlerChangeDataPassgeiro}
                    setPassageiros={setPassageiros}
                  />
                </Box>
              );
            })}
        </Grid>
        {/* <Grid>
          <BoxVoucherSeguroViagem />
        </Grid> */}
      </CarrinhoPage>
    </div>
  );
}
