import { makeStyles } from "@mui/styles";

export const Style = makeStyles((theme) => ({
  Container: {
    margin: "auto",
    padding: 10,
    background: "#fff",
  },
  Item: {
    padding: 20,
    border: "1px solid #ddd",
    background: "#fcfcfc",
    borderRadius: 10,
  },
  Check: {
    fontSize: "24px !important",
    paddingTop: "10px !important",
    marginRight: "2px !important",
  },
  Titulo: {
    display: "flex",
    marginBottom: "20px",
    alignItems: "center",
  },
  Modal: {
    width: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "750px !important",
    },
  },
  Bloco: {
    borderLeft: "none",
    width: "100%",
    marginTop: 21,
    borderTop: "1px solid #ccc",
    [theme.breakpoints.up("sm")]: {
      borderLeft: "1px solid #ccc",
      paddingLeft: 40,
      borderTop: "none",
      marginTop: 0,
      width: "auto",
    },
  },
  ValorFinal: {
    borderLeft: "none",
    marginTop: 12,
    textAlign: "left",
    paddingTop: 12,
    [theme.breakpoints.up("sm")]: {
      textAlign: "end",
      marginTop: 0,
      paddingTop: 0,

      borderTop: "none",
    },
  },
  Valor: {
    cursor: "pointer",
    fontWeight: "bold",
    ml: 1,
    display: "flex",
    fontSize: 24,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      mb: 3,
    },
  },
  span: {
    textDecoration: "underline",
    marginTop: '15px !important',
    [theme.breakpoints.up("sm")]: {
      cursor:'pointer',
      textDecoration: "underline",
    },
  },
}));
