import { useState, useEffect } from "react";
import CampoTexto from "../../Campos/CampoTexto";
import Seletor from "../../Seletor";
import Subtitulo from "../../Textos/Subtitulo";
import { getDadoBancario } from "../../../api/Cadastro";
import { Grid, Skeleton } from "@mui/material";
import useStyle from "./styles";
import CustomGrid from "../../GridCadastroCustom";

export default function DadoBancarioItem({
  idCliente,
  idPerfilLimiteOperacional,
  setDada,
  isLoading,
}) {
  const classes = useStyle();
  const [dadosForm, setDadosForm] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await getDadoBancario(
      idCliente,
      idPerfilLimiteOperacional
    );

    setDadosForm(response);
    setDada(response);
  };

  const handlerChangeValue = (e) => {
    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.value };
      else return c;
    });

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  const handlerChangeSelect = (name, value) => {
    setDadosForm({ ...dadosForm, [name]: value });
    setDada({ ...dadosForm, [name]: value });
  };

  return (
    <>
      <div className={classes.box}>
        {isLoading ? (
          <Skeleton animation="wave" width={"10%"} height={50} />
        ) : (
          <Subtitulo color="primary" label="Dados Bancários" />
        )}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {dadosForm.campos &&
            dadosForm.campos.map((c, i, array) => (
              <CustomGrid index={i} array={array} md={4}>
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    width={"100%"}
                    height={60}
                    sx={{}}
                  />
                ) : (
                  <CampoTexto
                    key={i}
                    enable={c.enable}
                    required={c.obrigatorio}
                    variant="filled"
                    size="small"
                    fullWidth={true}
                    defaultValue=" "
                    label={c.label}
                    name={c.propriedade}
                    value={c.valor}
                    handlerChange={handlerChangeValue}
                    className={classes.campo}
                    // isLoading={isLoading}
                  />
                )}
              </CustomGrid>
            ))}
          {dadosForm.idBancoNacional > 0 && (
            <Grid item xs={6} md={4}>
              {isLoading ? (
                <Skeleton animation="wave" width={"100%"} height={60} />
              ) : (
                <Seletor
                  sx={{ mt: 2 }}
                  setSelect={(e) => handlerChangeSelect("idBancoNacional", e)}
                  list={dadosForm.bancos}
                  size="small"
                  label="Bancos"
                  value={dadosForm.idBancoNacional}
                  isLoading={isLoading}
                  fullWidth={true}
                />
              )}
            </Grid>
          )}
          {dadosForm.idTipoContaBancaria > 0 && (
            <Grid item xs={6} md={4}>
              {isLoading ? (
                <Skeleton animation="wave" width={"100%"} height={60} />
              ) : (
                <Seletor
                  sx={{ mt: 2, width: "100%" }}
                  setSelect={(e) =>
                    handlerChangeSelect("idTipoContaBancaria", e)
                  }
                  list={dadosForm.tiposConta}
                  size="small"
                  fullWidth
                  label="Tipos de Conta"
                  value={dadosForm.idTipoContaBancaria}
                  isLoading={isLoading}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}
